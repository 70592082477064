"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/* global site */

window.site = window.site || {};

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = function App() {
	/**
  * Has the class been initialized?
  * @private
  */
	var inited = false;

	/**
  * Application config defaults.
  * @private
  * @param config.env     Current server environment
  * @param config.csrf    Security token to submit with forms
  * @param config.csrfName    Security token to submit with forms
  * @param config.locale  Current locale short code
  * @param config.device  Device detection based on browser signature
  * @param config.preview Page is shown through live preview mode
  * @param config.general Settings from general config
  */
	var config = {
		env: "production",
		csrf: null,
		csrfName: null,
		locale: "en",
		device: "desktop",
		preview: false,
		general: {}
	};

	/**
  * Initializes the class.
  * @public
  */
	var init = function init(options) {
		// Abort if already initialized
		if (inited) {
			return false;
		}

		inited = true;

		// Store application settings
		options = options || {};

		if (options.env) {
			config.env = options.env;
		}
		if (options.csrf) {
			config.csrf = options.csrf;
		}
		if (options.csrfName) {
			config.csrfName = options.csrfName;
		}
		if (options.locale) {
			config.locale = options.locale;
		}
		if (options.device) {
			config.device = options.device;
		}
		if (options.preview) {
			config.preview = options.preview;
		}
		if (options.general) {
			config.general = options.general;
		}

		// Initialize header before locSwitcher
		if (_typeof(site.HeaderDropwdown) === "object") {
			site.HeaderDropwdown.init();
		}
		if (_typeof(site.CalculateHeader) === "object") {
			site.CalculateHeader.init();
		}

		// Inititalize loc switcher
		if (_typeof(site.LocSwitcher) === "object") {
			site.LocSwitcher.init();
		}

		// Initialize child classes
		if (_typeof(site.ShareButton) === "object") {
			site.ShareButton.init();
		}
		// if (typeof site.AjaxPagination === 'object') { site.AjaxPagination.init(); }
		if (_typeof(site.MenuMobile) === "object") {
			site.MenuMobile.init();
		}
		if (_typeof(site.CookiePolicy) === "object") {
			site.CookiePolicy.init();
		}
		if (_typeof(site.ContactPage) === "object") {
			site.ContactPage.init();
		}
		if (_typeof(site.Hero) === "object") {
			site.Hero.init();
		}
		if (_typeof(site.Blog) === "object") {
			site.Blog.init();
		}

		if (_typeof(site.Showroom) === "object") {
			site.Showroom.init();
		}

		// if (typeof site.Map === 'object') { site.Map.init(); }
		if (_typeof(site.GeolocationServices) === "object") {
			site.GeolocationServices.init();
		}

		// Blocks
		if (_typeof(site.Split001) === "object") {
			site.Split001.init();
		}
		if (_typeof(site.Slider001) === "object") {
			site.Slider001.init();
		}
		if (_typeof(site.Slider002) === "object") {
			site.Slider002.init();
		}
		if (_typeof(site.Slider003) === "object") {
			site.Slider003.init();
		}
		if (_typeof(site.Slider004) === "object") {
			site.Slider004.init();
		}
		if (_typeof(site.Grid002) === "object") {
			site.Grid002.init();
		}
		// if (typeof site.scrollTo === 'object') { site.scrollTo.init(); }
		if (_typeof(site.Track) === "object") {
			site.Track.init();
		}
		if (_typeof(site.Content001) === "object") {
			site.Content001.init();
		}
		if (_typeof(site.List003) === "object") {
			site.List003.init();
		}
		if (_typeof(site.List004) === "object") {
			site.List004.init();
		}
		if (_typeof(site.Faq001) === "object") {
			site.Faq001.init();
		}
		if (_typeof(site.Modal) === "object") {
			site.Modal.init();
		}
		if (_typeof(site.ShowVideos) === "object") {
			site.ShowVideos.init();
		}

		if (_typeof(window.site.PlayGround) === "object") {
			window.site.PlayGround.init();
		}

		// Return success
		return true;
	};

	/**
  * Getter for application config.
  * @public
  */
	var getConfig = function getConfig(option) {
		return config[option] ? config[option] : false;
	};

	/**
  * Expose public methods & properties.
  */
	return {
		init: init,
		config: getConfig
	};
}();