"use strict";

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var row1 = [{
	cost: 8880,
	lot: 24
}, {
	cost: 8880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 8880,
	lot: 24
}, {
	cost: 8880,
	lot: 24
}];

var row2 = [{
	cost: 8880,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}, {
	cost: 8880,
	lot: 24
}];

var row3 = [{
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 18
}, {
	cost: 7200,
	lot: 18
}, {
	cost: 7200,
	lot: 18
}, {
	cost: 6280,
	lot: 18
}, {
	cost: 6280,
	lot: 18
}, {
	cost: 7200,
	lot: 18
}, {
	cost: 7200,
	lot: 18
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}];

var row4 = [{
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}];

var row5 = [{
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 5500,
	lot: 12
}, {
	cost: 5500,
	lot: 12
}, {
	cost: 5500,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}];

var row6 = [{
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 5500,
	lot: 12
}, {
	cost: 5500,
	lot: 12
}, {
	cost: 5500,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}];

var row7 = [{
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 5500,
	lot: 12
}, {
	cost: 5500,
	lot: 12
}, {
	cost: 5500,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}];

var row8 = [{
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6280,
	lot: 12
}, {
	cost: 6880,
	lot: 18
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}];

var row9 = [{
	cost: 7880,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 18
}, {
	cost: 6500,
	lot: 18
}, {
	cost: 6500,
	lot: 18
}, {
	cost: 6500,
	lot: 18
}, {
	cost: 6500,
	lot: 18
}, {
	cost: 6500,
	lot: 18
}, {
	cost: 7200,
	lot: 18
}, {
	cost: 7880,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}];

var row10 = [{
	cost: 8880,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}, {
	cost: 8880,
	lot: 24
}];

var row11 = [{
	cost: 8880,
	lot: 24
}, {
	cost: 8880,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7200,
	lot: 24
}, {
	cost: 7880,
	lot: 24
}, {
	cost: 8880,
	lot: 24
}, {
	cost: 8880,
	lot: 24
}];

var costMatrix = [row1, row2, row3, row4, row5, row6, row7, row8, row9, row10, row11];

// On Vue Ready
loadjs.ready(["vue", "axios"], {
	success: function success() {
		var el = document.querySelector("#calculator-component");

		// Abort if not on the APP scope
		if (!el) {
			return;
		}

		// Create our Vue component
		var DocbracesCalculator = new Vue({
			el: el,
			data: {
				step: 1,
				stepsContent: {
					step1: [].concat(_toConsumableArray(JSON.parse(el.getAttribute("data-step-one-image-labels")).map(function (label, score) {
						return {
							score: score,
							label: label
						};
					}))),
					step2: [].concat(_toConsumableArray(JSON.parse(el.getAttribute("data-step-two-image-labels")).map(function (label, score) {
						return {
							score: score,
							label: label
						};
					})))
				},
				answers: {
					step1: 5,
					step2: 5,
					step3: null
				},
				calculatedPrice: {
					unite: null,
					decimal: null
				},
				insurance: 0,
				notSure: false,
				completed: false
			},
			methods: {
				registerAnswer: function registerAnswer(answer) {
					// Get score of link click and save it to answers.step${this.step}
					if (answer.currentTarget.hasAttribute("data-coverage")) {
						this.answers["step" + this.step] = answer.currentTarget.getAttribute("data-coverage");
					}

					if (answer.currentTarget.hasAttribute("data-score")) {
						this.answers["step" + this.step] = parseInt(answer.target.value);
					}

					// Set value of aria-valuetext as current value
					if (answer.currentTarget.hasAttribute("aria-valuetext")) {
						answer.currentTarget.setAttribute("aria-valuetext", this.stepsContent["step" + this.step][answer.target.value].label);
					}
				},
				goToPrev: function goToPrev() {
					var step = this.step - 1;

					this.goToStep(step);
				},
				goToNext: function goToNext() {
					var step = this.step + 1;

					this.goToStep(step);
				},
				goToStep: function goToStep(step) {
					if (this.validateStep(step)) {
						this.step = step;
					}
				},
				validateStep: function validateStep(step) {
					if (step < 1 && step > this.answers.length) {
						this.doAlert({
							type: "error",
							title: "This is not a valid step."
						});
						return false;
					}

					if (step === 2 && this.answers.step1 === null) {
						this.doAlert({
							type: "error",
							title: "You must first choose an answer for step 1"
						});

						return false;
					}

					if (step === 3 && this.answers.step2 === null) {
						this.doAlert({
							type: "error",
							title: "You must first choose an answer for step 2"
						});

						return false;
					}

					if (step === 4 && !this.answers.step3 || step === 4 && this.answers.step3.score === "yes" && !parseFloat(this.coverage) && !this.notSure) {
						this.doAlert({
							type: "error",
							title: "You must answer the remaining questions on step 3"
						});

						return false;
					}

					return true;
				},
				doAlert: function doAlert(_ref) {
					var type = _ref.type,
					    title = _ref.title;

					var Toast = Swal.mixin({
						toast: true,
						position: "top-center",
						showConfirmButton: false,
						timer: 3000
					});

					Toast.fire({
						type: type,
						title: title
					});
				},
				toggleNotSure: function toggleNotSure() {
					this.notSure = !this.notSure;

					if (this.notSure) {
						this.insurance = 0;
					}
				},
				calculateCost: function calculateCost() {
					var additionalMonthsAfterTreatment = 12;
					var biweeklyPrice = 2;

					var costMatrixValue = costMatrix[this.answers.step2][this.answers.step1];
					var costOfTreatment = costMatrixValue.cost;
					var lengthOfTreatment = costMatrixValue.lot;

					// Total equation cost
					// ((costofTreatment - insurance) / (lengthOfTreatment + additionalMonthsAfterTreatment)) / biweeklyPrice
					var total = (costOfTreatment - parseInt(this.insurance)) / (lengthOfTreatment + additionalMonthsAfterTreatment) / biweeklyPrice;

					var unite = Math.floor(total);
					var decimal = Math.floor(total % 1 * 100);

					this.calculatedPrice = {
						unite: unite,
						decimal: decimal
					};

					this.completed = true;

					this.goToStep(4);
				},
				startOver: function startOver() {
					this.step = 1;
					this.answers = {
						step1: 5,
						step2: 5,
						step3: null
					};
					this.calculatedPrice = {
						unite: null,
						decimal: null
					};
					this.insurance = 0;
					this.coverage = 0;
					this.notSure = false;
					this.completed = false;
				}
			},
			mounted: function mounted() {
				this.$refs.step1range.defaultValue = this.answers.step1;
				this.$refs.step2range.defaultValue = this.answers.step2;
			},
			updated: function updated() {
				if (this.calculatedPrice.unite === null || !this.calculatedPrice.decimal === null || !this.$refs.priceUnite || !this.$refs.priceDecimal || this.step !== 4) {
					return;
				}

				// Type writer animation
				var priceUniteRef = this.$refs.priceUnite;
				var priceDecimalRef = this.$refs.priceDecimal;
				var priceUnite = this.calculatedPrice.unite.toString();
				var priceDecimal = this.calculatedPrice.decimal.toString();

				// Set empty text
				priceUniteRef.innerHTML = "$";
				priceDecimalRef.innerHTML = "";

				var i = 0;
				var speed = 150; /* The speed/duration of the effect in milliseconds */

				setTimeout(typeWriter, 1500);

				function typeWriter() {
					if (i < priceUnite.length + priceDecimal.length) {
						if (i < priceUnite.length) {
							priceUniteRef.innerHTML += priceUnite.charAt(i);
						} else if (i >= priceUnite.length) {
							// If index is greater than priceUnite length,
							// add priceDecimal to priceDecimalRef
							priceDecimalRef.innerHTML += priceDecimal.charAt(i - priceDecimal.length - 1);
						}

						i++;
						setTimeout(typeWriter, speed);
					}
				}
			}
		});
	}
});