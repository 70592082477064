'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Faq001 = function Faq001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $trigger = document.querySelectorAll('.faq-question-title');

    function interactItem(item, status) {
      var title = item.querySelector('.faq-text');
      var text = item.querySelector('.faq-answer');
      var chevron = item.querySelector('.icon-chevron-right');

      var chevronClass = status == 'open' ? 'close' : 'is-active';
      var textClass = status == 'open' ? 'is-active' : 'close';

      console.log(status);

      if (status == 'close') {

        item.classList.remove('close');
      } else {

        item.classList.add(chevronClass);

        chevron.classList.add(chevronClass);

        text.classList.add(textClass);

        title.classList.add(textClass);
      }
    };

    document.addEventListener('DOMContentLoaded', function () {

      // if (window.location.pathname == '/faq') {

      var currentQuestion = document.querySelector('[data-scroll-question]');

      if (currentQuestion) {
        //srollTo

        var menuHeight = document.querySelector('.main-header').offsetHeight + 40;

        var topPos = currentQuestion.getBoundingClientRect().top + window.scrollY - menuHeight;

        window.scroll({
          top: topPos,
          behavior: "smooth"
        });

        //open

        interactItem(currentQuestion, 'open');
      }

      $trigger.forEach(function (item) {

        item.addEventListener('click', function (e) {

          e.preventDefault();

          if (item.parentNode.parentNode.parentNode.classList.contains('close')) {

            interactItem(item.parentNode.parentNode.parentNode, 'close');
          } else {

            $trigger.forEach(function (otherItem) {
              interactItem(otherItem.parentNode.parentNode.parentNode, 'close');
            });

            interactItem(item.parentNode.parentNode.parentNode, 'open');
          }
        });
      });
      // } else {
      //
      //   $trigger.forEach(function (item) {
      //     const faq = item.parentNode.parentNode.parentNode;
      //
      //     if (faq.id) {
      //
      //       faq.addEventListener('click', function (e) {
      //         let id = faq.id;
      //         window.location.href = '/faq?question=' + id;
      //       });
      //     } else {
      //       faq.addEventListener('click', function (e) {
      //         window.location.href = '/faq';
      //       });
      //     }
      //
      //   });
      //
      // }
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();