'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.HeaderDropwdown = function HeaderDropwdown() {
  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $dropdownLinks = [].slice.call(document.querySelectorAll('.header-link-wrapper.js-dropdown'));
    var $dropdownOverlay = document.querySelector('.dropdown-overlay');

    var $timer = null;

    if ($dropdownLinks) {
      $dropdownLinks.forEach(function (item) {
        var dropdownItem = item.querySelector('.header-dropdown');
        var headerLink = item.querySelector('.header-link');

        item.addEventListener('mouseenter', function () {
          clearTimeout($timer);
          closeAllSubmenus();
          openSubmenu(dropdownItem, $dropdownOverlay, headerLink);
        });

        $dropdownOverlay.addEventListener('click', function () {
          $timer = setTimeout(function () {
            closeSubmenu(dropdownItem, $dropdownOverlay, headerLink);
          }, 500);
        });
      });
    }

    return true;
  };

  var openSubmenu = function openSubmenu(submenu, overlay, headerLink) {
    submenu && submenu.classList.add('is-active');
    overlay && overlay.classList.add('is-active');
    headerLink && headerLink.classList.add('is-active');
  };

  var closeSubmenu = function closeSubmenu(submenu, overlay, headerLink) {
    submenu.classList.remove('is-active');
    overlay.classList.remove('is-active');
    headerLink.classList.remove('is-active');
  };

  var closeAllSubmenus = function closeAllSubmenus() {
    var $navigationLinks = [].slice.call(document.querySelectorAll('.header-link-wrapper.js-dropdown'));
    var $dropdownOverlay = document.querySelector('.dropdown-overlay');

    $dropdownOverlay.classList.remove('is-active');

    $navigationLinks.forEach(function (navigationLink) {
      var submenu = navigationLink.querySelector('.header-dropdown');
      var headerLink = navigationLink.querySelector('.header-link');

      if (submenu) {
        if (submenu.classList.contains('is-active')) {
          submenu.classList.remove('is-active');
          headerLink.classList.remove('is-active');
        }
      }
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();