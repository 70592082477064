"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.CookiePolicy = function CookiePolicy() {
	"use strict";

	/**
  * jQuery elements
  * @private
  */

	/**
  * Has the class been initialized?
  * @private
  */

	var inited = false;

	/**
  * Initializes the class.
  * @public
  */
	var init = function init() {
		// Abort if already initialized
		if (inited) {
			return false;
		}

		inited = true;

		var $cookiePolicy = document.querySelector(".cookie-confirmation");

		if (!$cookiePolicy) {
			return false;
		}

		var $triggerAccept = $cookiePolicy.querySelector(".confirmation-button");

		if (localStorage.getItem("cookiePolicyAccepted") !== "accepted") {
			$cookiePolicy.classList.add("is-active");

			$triggerAccept.addEventListener("click", function (e) {
				e.preventDefault();
				localStorage.setItem("cookiePolicyAccepted", "accepted");
				$cookiePolicy.classList.remove("is-active");
			});
		}

		return true;
	};

	// Expose public methods & properties
	return {
		init: init
	};
}();