'use strict';

// const replace = require("gulp-replace");

window.site = window.site || {};

/**
 * Utilities for GeolocationServices
 * @class GeolocationServices
 * @static
 */
site.LocSwitcher = function LocSwitcher() {
  /**
  * Has the class been initialized?
  * @private
  */
  var inited = false;
  var geolocationConfig = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  /**
  * Initializes the class.
  * @public
  */
  var init = function init() {
    console.log("_loc-switcher init");

    var config = site.App.config('general');

    var province = window.localStorage.getItem('defaultSiteRegion');
    if (province === null) {
      province = document.body.getAttribute('data-province');
    }
    province = province ? province.toUpperCase() : null;

    // get userLat
    var userLat = window.localStorage.getItem('defaultSiteLat');
    if (userLat === null) {
      userLat = document.body.getAttribute('data-user-lat');
    }

    // get userLng
    var userLng = window.localStorage.getItem('defaultSiteLng');
    if (userLng === null) {
      userLng = document.body.getAttribute('data-user-lng');
    }

    var popupProvince = document.querySelector('.popup-location');

    function getCurrentLocation() {
      var url = window.location.pathname;

      var segments = url.split('/');
      var currentProvince = 'ca';

      if (segments.length && segments[1].length === 2) {
        currentProvince = segments[1];
      }

      return currentProvince;
    }

    function getReplacedUrl(selectedProvince) {
      var domainName = window.location.host; // localhost:8888, docbraces.com
      var currentProvince = getCurrentLocation();

      window.localStorage.setItem('defaultLang', selectedProvince);

      var currentReplacement = domainName;

      if (currentProvince !== 'ca') {
        currentReplacement = domainName + '/' + currentProvince;
      }

      var replacedUrl = window.location.href;

      if (selectedProvince === 'ca') {
        replacedUrl = replacedUrl.replace(currentReplacement, domainName);
      } else {
        replacedUrl = replacedUrl.replace(currentReplacement, domainName + '/' + selectedProvince);
      }

      return replacedUrl;
    }

    function redirectToProvince(selectedProvince) {
      var replacedUrl = getReplacedUrl(selectedProvince);
      window.location.replace(replacedUrl);
    }

    function findProvinceByCoords(lat, lng) {
      var geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: { lat: parseFloat(lat), lng: parseFloat(lng) } }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {

          var currentLocation = results[0].formatted_address;
          var locationList = currentLocation.split(', ');
          var region = locationList[locationList.length - 2].slice(0, 2);

          // update province
          province = region;

          // set province to local storage
          window.localStorage.setItem('defaultSiteRegion', region);

          // redirect
          var currentProvince = region.toLowerCase();
          console.log('Current province', currentProvince);
          if (['ab', 'bc', 'nb', 'ns', 'pe', 'yt', 'on'].includes(currentProvince)) {
            console.log('Province found');
            defaultLang = currentProvince;
          } else {
            console.log('Default province selected!');
            defaultLang = 'ca';
          }
          redirectToProvince(defaultLang);
        } else {
          console.log("Geocoder failed due to: " + status);

          // set local storage and redirect based on default position info -- from body tag (CA, 43, -79)
          window.localStorage.setItem('defaultSiteLat', userLat);
          window.localStorage.setItem('defaultSiteLng', userLng);
          redirectToProvince(province.toLowerCase());
        }
      });
    }

    function onReceivePosition(position) {
      var lat = position.coords.latitude;
      var lng = position.coords.longitude;

      // set user lat and lng
      userLat = lat;
      userLng = lng;

      // set user lat and lng to local storage
      window.localStorage.setItem('defaultSiteLat', lat);
      window.localStorage.setItem('defaultSiteLng', lng);

      // find province and set it
      findProvinceByCoords(lat, lng);
    }

    function findPositionIpinfo() {
      fetch("https://ipinfo.io/json?token=" + config.ipinfoIO) // 32bc023dc176b9
      .then(function (response) {
        return response.json();
      }).then(function (jsonResponse) {
        var latAndLng = jsonResponse.loc.split(',');

        // set user lat and lng
        userLat = latAndLng[0];
        userLng = latAndLng[1];

        // set user lat and lng to local storage
        window.localStorage.setItem('defaultSiteLat', latAndLng[0]);
        window.localStorage.setItem('defaultSiteLng', latAndLng[1]);

        // find province and set it
        findProvinceByCoords(latAndLng[0], latAndLng[1]);
      }).catch(function (error) {
        console.log("_loc_switcher.js ipinfo api call error: ", error);

        // set local storage and redirect based on default position info -- from body tag (CA, 43, -79)
        window.localStorage.setItem('defaultSiteLat', userLat);
        window.localStorage.setItem('defaultSiteLng', userLng);
        redirectToProvince(province.toLowerCase());
      });
    }

    function onError(error) {
      console.log("_loc_switcher.js get into onError with error code: ", error.code);
      // when fail to get current position by geolocation (including permission denied), then get it by ipinfo
      // when browser location turned off, then onError is invoked after timeout interval
      findPositionIpinfo();
    }

    function addEventsToComponents() {
      // if (popupProvince.classList.contains('is-active')) {
      //
      // 	let popupBtn = popupProvince.querySelector('.popup-submit-province');
      // 	let popupSelect = popupProvince.querySelector('select');
      //
      // 	popupBtn.addEventListener('click', function (e) {
      // 		e.preventDefault();
      //
      // 		let selectedValue = popupSelect.options[popupSelect.selectedIndex].value;
      //
      // 		if (selectedValue !== null) {
      // 			redirectToProvince(selectedValue);
      // 		}
      // 	});
      //
      // 	popupProvince.querySelector('.overlay').addEventListener('click', function () {
      //
      // 		popupProvince.classList.remove('is-active');
      // 	});
      //
      // 	popupProvince.querySelector('.popup-close').addEventListener('click', function () {
      //
      // 		popupProvince.classList.remove('is-active');
      // 	});
      // }

      // if (province == 'BC') {
      //   document.addEventListener('DOMContentLoaded', (e) => {
      //     const textNodesUnder = function (el) {
      //       let n;
      //       const a = [];
      //       const walk = document.createTreeWalker(
      //         el,
      //         NodeFilter.SHOW_TEXT,
      //         null,
      //         false,
      //       );
      //       while ((n = walk.nextNode())) a.push(n);
      //       return a;
      //     };
      //     const textNodes = textNodesUnder(
      //       document.getElementsByTagName('body')[0],
      //     );
      //     for (let i = 0; i < textNodes.length; i++) {
      //       textNodes[i].nodeValue = textNodes[i].nodeValue.replace(
      //         /free consultation/gi,
      //         'consultation',
      //       );
      //     }
      //   });
      // }

      document.addEventListener('DOMContentLoaded', function (e) {
        function deg2rad(deg) {
          return deg * (Math.PI / 180);
        }

        function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
          var R = 6371; // Radius of the earth in km
          var dLat = deg2rad(lat2 - lat1); // deg2rad below
          var dLon = deg2rad(lon2 - lon1);
          var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          var d = R * c; // Distance in km
          return d;
        }

        var locationSelect = document.getElementById('locations');

        var defaultLocation = window.localStorage.getItem('defaultLocation');
        var phoneLink = document.getElementById('closest-phone-link');
        var phone = document.getElementById('closest-phone');
        var navLinks = document.getElementsByClassName('nav-link');
        var subNavLinks = document.getElementsByClassName('link-subnav');
        var popoverLearnMore = document.getElementById('popover-learn-more');
        var popoverDefaultLocation = document.querySelectorAll('.popover-default-location');

        var closest = void 0;

        var locations = locationSelect.querySelectorAll('.locations-select__item');

        if (defaultLocation) {
          for (var i = 0; i < locations.length; i++) {
            var option = locations[i];

            if (option.href == defaultLocation) {
              closest = option;
            }
          }
        } else if (userLat && userLng) {
          var shortestDistance = -1;

          for (var i = 0; i < locations.length; i++) {
            var option = locations[i];

            var lat = option.getAttribute('data-lat');
            var lng = option.getAttribute('data-lng');
            var distance = getDistanceFromLatLonInKm(userLat, userLng, lat, lng);

            if (shortestDistance == -1 || distance < shortestDistance) {
              shortestDistance = distance;
              closest = option;

              // Set local storage
              var _lat = closest.getAttribute('data-lat');
              var _lng = closest.getAttribute('data-lng');

              localStorage.setItem('defaultLatLng', JSON.stringify({ lat: _lat, lng: _lng }));
            }
          }
        }

        if (closest) {
          closest.classList.toggle('locations-select__item--default');
          phoneLink.href = closest.getAttribute('data-phone');
          phone.innerHTML = closest.getAttribute('data-phone');
          popoverLearnMore.href = closest.href;
          popoverDefaultLocation.forEach(function (l) {
            l.innerHTML = closest.innerHTML.toLowerCase();
          });

          for (var i = 0; i < navLinks.length; i++) {
            if (navLinks[i].text && navLinks[i].text.toLowerCase() == 'contact') {
              navLinks[i].href = closest.href;
            }
          }

          for (var i = 0; i < subNavLinks.length; i++) {
            if (subNavLinks[i].text && subNavLinks[i].text.toLowerCase() == 'contact') {
              subNavLinks[i].href = closest.href;
            }
          }
        } else {
          phoneLink.style.visibility = 'hidden';
        }
      });

      // Abort if already initialized
      if (inited) {
        return false;
      }

      inited = true;

      var setDefaultLocation = document.querySelectorAll('.set-default-location');

      setDefaultLocation.forEach(function (l) {
        l.addEventListener('click', function (evt) {
          // current url, use this url to find the default clinic and set info @ header
          var url = window.location.protocol + '//' + window.location.host + window.location.pathname;

          // get elements
          evt.target.outerHTML = 'default changed';
          var phoneLink = document.getElementById('closest-phone-link');
          var phone = document.getElementById('closest-phone');
          var locationSelect = document.getElementById('locations');
          var locations = locationSelect.querySelectorAll('.locations-select__item');

          var newDefaultLat = null;
          var newDefaultLng = null;

          for (var i = 0; i < locations.length; i++) {
            var option = locations[i];
            option.classList.remove('locations-select__item--default');

            if (option.href == url) {
              // compare with current url
              option.classList.add('locations-select__item--default');
              phoneLink.href = option.getAttribute('data-phone');
              phone.innerHTML = option.getAttribute('data-phone');

              newDefaultLat = option.getAttribute('data-lat');
              newDefaultLng = option.getAttribute('data-lng');
            }
          }

          // get province of selected clinic
          var selectedProvince = l.dataset.province;
          var selectedProvinceSlug = l.dataset.slug;
          window.localStorage.setItem('defaultLocationSlug', selectedProvinceSlug);

          // reset default lat and lng
          console.log("_loc-switcher.js set default clinic location lat: ", newDefaultLat);
          console.log("_loc-switcher.js set default clinic location lng: ", newDefaultLng);

          if (newDefaultLat !== null && newDefaultLng !== null) {
            window.localStorage.setItem('defaultLatLng', JSON.stringify({ lat: newDefaultLat, lng: newDefaultLng }));
          }

          // Check if the location is in a different province, then set default clinic and do redirection
          var replacedUrl = getReplacedUrl(selectedProvince);
          console.log("_loc_switcher.js set clinic as default: ", replacedUrl);
          window.localStorage.setItem('defaultLocation', replacedUrl); // store url in local storage with correct province

          var currentProvince = getCurrentLocation();
          if (currentProvince !== selectedProvince) {
            redirectToProvince(selectedProvince, true);
          }
        });
      });

      var locationsSwitchers = document.querySelectorAll('[data-js-locations]');

      locationsSwitchers.forEach(function (element) {
        element.addEventListener('change', function (el) {
          return function () {
            if (el.selectedIndex === 0) return;

            var value = element.options[el.selectedIndex].value;
            var url = element.options[el.selectedIndex].dataset.url;

            window.localStorage.setItem('defaultLocation', url);
            window.location.href = url;
          };
        }(element));
      });
    }

    // check whether the first access
    var defaultLang = window.localStorage.getItem('defaultLang');

    if (defaultLang === null) {
      // first time reach the site, no localstorage and no cookies, then get the correct position and set it to local storage and do the redirection

      if (navigator.geolocation) {
        // if navigator.geolocation exists
        navigator.geolocation.getCurrentPosition(onReceivePosition, onError, geolocationConfig);
      } else {
        // if navigator.geolocation not exists
        findPositionIpinfo();
      }
    } else {
      // not first access, have defaultLang set in local storage
      var curProvince = getCurrentLocation();

      if (curProvince !== defaultLang) {
        // different province, then do re-direction
        redirectToProvince(defaultLang);
      } else {
        // same province, then add event listeners
        addEventsToComponents();
      }
    }
    return true;
  };

  return {
    init: init
  };
}();