'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.ShowVideos = function ShowVideos() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $triggers = [].slice.call(document.querySelectorAll('[data-video]'));

    if ($triggers) {
      $triggers.forEach(function ($trigger) {
        $trigger.addEventListener('click', function (e) {

          var ua = window.navigator.userAgent;
          var msie = ua.indexOf("MSIE ");

          if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return true;
          } else {
            e.preventDefault();

            var iframeUrl = $trigger.getAttribute('href');

            basicLightbox.create('<iframe src="' + iframeUrl + '" width="960" height="540" frameborder="0" allowfullscreen></iframe>').show();
          }
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();