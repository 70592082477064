'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

window.site = window.site || {};

/**
 * Utilities for GeolocationServices
 * @class GeolocationServices
 * @static
 */
site.GeolocationServices = function GeolocationServices() {

  'use strict';

  return {
    init: function init() {}
  };

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var config = {
    enableHighAccuracy: true,
    timeout: 30000,
    maximumAge: 0
  };

  var userLat = null;
  var userLng = null;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    console.log('init');

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var config = site.App.config('general');

    if (config.googleMaps && config.googleMaps !== '') {
      setTimeout(function () {
        initGeolocation();
      }, 755);
    }

    return true;
  };
  var initGeolocation = function initGeolocation() {

    console.log('initGeolocation');

    if (navigator.geolocation && (window.location.pathname === '/' || window.location.pathname === '/en/')) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(showPosition, showError, config);
    }
  };
  var showPosition = function showPosition(position) {

    userLat = position.coords.latitude;
    userLng = position.coords.longitude;

    orderLocationsByDistance();

    var geocoder = new google.maps.Geocoder();

    var $loader = '<div class="loader-container" style="height: 20px;"><div class="loader" style="width: 20px;"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div></div>';

    geocoder.geocode({ location: { lat: parseFloat(userLat), lng: parseFloat(userLng) } }, function (results, status) {

      if (status === google.maps.GeocoderStatus.OK) {

        var address = '<span id="count1">' + parseFloat(String(position.coords.latitude).substr(0, 7), 3) + '</span>&deg;&nbsp;N,&nbsp;<span id="count2">' + parseFloat(String(position.coords.longitude).substr(0, 7), 3) + '</span>&deg;&nbsp;W';

        tempLog(address, parseFloat(String(position.coords.latitude).substr(0, 7), 3), parseFloat(String(position.coords.longitude).replace('-', '').substr(0, 7), 3));
      }
    });
  };

  var showError = function showError(error) {

    switch (error.code) {
      case error.PERMISSION_DENIED:
        // tempLog("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        // tempLog("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        // tempLog("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        // tempLog("An unknown error occurred.");
        break;
    }
  };

  var tempLog = function tempLog(txt, lat, lng) {

    if (!$('#geolocation').length) {
      return;
    }

    $('#geolocation').html(txt);

    var options = {
      useEasing: true,
      useGrouping: true,
      separator: ',',
      decimal: '.'
    };
    var count1 = new CountUp('count1', 0, lat, 3, 2.5, options);
    if (!count1.error) {
      count1.start();
    } else {
      console.error(count1.error);
    }

    var count2 = new CountUp('count2', 0, lng, 3, 2.5, options);
    if (!count2.error) {
      count2.start();
    } else {
      console.error(count2.error);
    }

    // $('#geolocation').text('');
  };

  var orderLocationsByDistance = function orderLocationsByDistance() {

    var unorderedLocations = {};
    var $locations = document.querySelectorAll('.locations-select option');

    [].slice.call($locations).forEach(function ($location) {
      var locLat = $location.getAttribute('data-lat');
      var locLng = $location.getAttribute('data-lng');
      console.log($location);

      var distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(userLat, userLng), new google.maps.LatLng(locLat, locLng));

      unorderedLocations[distance] = $location;
    });

    console.log(unorderedLocations);

    var unsorted = Object.keys(unorderedLocations),
        sorted = unsorted.sort(function (a, b) {
      return a - b;
    });

    console.log('sorted : ' + sorted);

    var closestLocations = {};

    Object.keys(sorted).sort().forEach(function (key) {
      closestLocations[key] = {
        option: unorderedLocations[sorted[key]],
        distance: (sorted[key] / 1000).toFixed(1)
      };
      console.log(typeof key === 'undefined' ? 'undefined' : _typeof(key));
      console.log(unorderedLocations[sorted[key]]);
      console.log((sorted[key] / 1000).toFixed(1) + 'km');
    });

    console.log(closestLocations);

    window.localStorage.setItem('location', closestLocations[0].option.value);
  };

  return {
    init: init,
    initGeolocation: initGeolocation
  };
}();