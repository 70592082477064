"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.CalculateHeader = function CalculateHeader() {
	/**
  * Has the class been initialized?
  * @private
  */
	var inited = false;

	/**
  * Initializes the class.
  * @public
  */
	var init = function init() {
		// Abort if already initialized
		if (inited) {
			return false;
		}

		inited = true;

		var header = document.querySelector("#main-header");
		var main = document.querySelector(".main");

		if (header && main) {
			setHeight(header, main);

			// Set height everytime the window gets resized
			window.addEventListener("resize", function () {
				setHeight(header, main);
			});
		}
	};

	var setHeight = function setHeight(header, main) {
		// Get header height
		var headerHeight = header.getBoundingClientRect().height;

		// Set margin top of main element
		main.style.marginTop = headerHeight + "px";
	};

	// Expose public methods & properties
	return {
		init: init
	};
}();