'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Blog = function Blog() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var categoriesInputs = document.querySelectorAll('.sort-category input');
    var categoriesForm = document.querySelector('form#categoriesForm');

    if (categoriesInputs) {
      categoriesInputs.forEach(function (input) {
        input.addEventListener('change', function () {
          categoriesForm.submit();
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();