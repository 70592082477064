"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Modal = function Modal() {
	"use strict";

	/**
  * jQuery elements
  * @private
  */

	/**
  * Has the class been initialized?
  * @private
  */

	var inited = false;

	/**
  * Initializes the class.
  * @public
  */
	var init = function init() {
		// Abort if already initialized
		if (inited) {
			return false;
		}

		inited = true;

		var modal = document.querySelector("#modal");
		var html = document.querySelector("body");

		var modalActiveClass = "modal--active";
		var preventOverflowClass = "mobile-is-open";

		if (!modal) {
			return;
		}

		// First thing if modal exists on the page is to make it visible
		modal.classList.add(modalActiveClass);

		// Check if modal is open
		var isOpen = modal.classList.contains(modalActiveClass);

		if (isOpen) {
			html.classList.add(preventOverflowClass);

			modal.addEventListener("click", function (e) {
				// If click target's parent is not in modal__container
				// Close modal
				if (!e.target.closest(".modal__container")) {
					html.classList.remove(preventOverflowClass);
					modal.classList.remove(modalActiveClass);
					modal.setAttribute("aria-hidden", false);
				}
			});
		}

		// Close button
		var closeButton = modal.querySelector("[data-modal-close-button]");

		if (!closeButton) {
			return;
		}

		closeButton.addEventListener("click", function (e) {
			html.classList.remove(preventOverflowClass);
			modal.classList.remove(modalActiveClass);
			modal.setAttribute("aria-hidden", false);
		});

		return true;
	};

	// Expose public methods & properties
	return {
		init: init
	};
}();