'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Map = function Map() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   */
  var $maps = $('[data-map]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    console.log("[Map.js] init();");

    // Load Google Maps script
    if ($maps.length) {

      console.log("1");

      var config = site.App.config('general');
      var locale = site.App.config('locale');

      if (config.googleMaps && config.googleMaps !== '') {

        console.log("2");

        var $body = document.querySelector("body");

        $body.innerHTML += '<script src="https://maps.googleapis.com/maps/api/js?v=3&callback=site.Map.initMaps&language=' + locale + '&key=' + config.googleMaps + '"><script/>';
      }
    }

    // initMaps();

    // Return success
    return true;
  };

  /**
   * Initialize Google Maps instances.
   * @public
   */
  var initMaps = function initMaps($elements) {

    console.log("initMaps();");

    if (typeof $elements === 'undefined') {
      $elements = $('[data-map]');
    }

    $elements.forEach(function (element) {

      console.log(element);
    });
  };

  var initGoogleMapBlocks = function initGoogleMapBlocks() {
    var geocoder = new google.maps.Geocoder();
    var blocks = document.getElementsByClassName('google-map');

    for (var i = 0; i < blocks.length; i++) {
      var block = blocks[i];
      var map;

      if (block.className.indexOf('many-markers') > -1) {
        map = new google.maps.Map(block, {
          zoom: 5,
          styles: [{
            "featureType": "all",
            "elementType": "all",
            "stylers": [{
              "visibility": "on"
            }, {
              "hue": "#00caff"
            }]
          }, {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{
              "saturation": -70
            }]
          }, {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "featureType": "water",
            "elementType": "all",
            "stylers": [{
              "visibility": "simplified"
            }, {
              "saturation": -60
            }]
          }]
        });

        var markerLocations = document.getElementsByClassName('marker-location');
        var bounds = new google.maps.LatLngBounds();
        for (var j = 0; j < markerLocations.length; j++) {
          var latLng = markerLocations[j].getAttribute('data-lat-lng').split(',');
          var url = markerLocations[j].getAttribute('data-url');

          var position = new google.maps.LatLng(latLng[0], latLng[1]);

          var marker = new google.maps.Marker({
            position: position,
            icon: new google.maps.MarkerImage('/img/icons/map-thumbnail.png', null, null, null, new google.maps.Size(42, 60)),
            map: map
          });

          google.maps.event.addListener(marker, 'click', function (url) {
            return function () {
              window.location.href = url;
            };
          }(url));

          bounds.extend(marker.position);
        }

        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
      } else {
        if (block.className.indexOf('manual-marker') > -1) {
          var latLng = {
            lat: Number(block.getAttribute("data-lat")),
            lng: Number(block.getAttribute("data-lng"))
          };
          var zoom = Number(block.getAttribute("data-zoom"));

          if (!zoom) {
            zoom = 15;
          }

          map = new google.maps.Map(block, {
            zoom: zoom,
            center: latLng,
            styles: [{
              "featureType": "all",
              "elementType": "all",
              "stylers": [{
                "visibility": "on"
              }, {
                "hue": "#00caff"
              }]
            }, {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "road",
              "elementType": "all",
              "stylers": [{
                "saturation": -70
              }]
            }, {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "water",
              "elementType": "all",
              "stylers": [{
                "visibility": "simplified"
              }, {
                "saturation": -60
              }]
            }]
          });

          var marker = new google.maps.Marker({
            position: latLng,
            icon: new google.maps.MarkerImage('/img/icons/map-thumbnail.png', null, null, null, new google.maps.Size(42, 60)),
            map: map
          });
        } else {
          map = new google.maps.Map(block, {
            zoom: 15,
            styles: [{
              "featureType": "all",
              "elementType": "all",
              "stylers": [{
                "visibility": "on"
              }, {
                "hue": "#00caff"
              }]
            }, {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "road",
              "elementType": "all",
              "stylers": [{
                "saturation": -70
              }]
            }, {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [{
                "visibility": "off"
              }]
            }, {
              "featureType": "water",
              "elementType": "all",
              "stylers": [{
                "visibility": "simplified"
              }, {
                "saturation": -60
              }]
            }]
          });

          geocoder.geocode({
            'address': block.getAttribute('data-address')
          }, function (results, status) {
            if ('OK' == status) {
              map.setCenter(results[0].geometry.location);

              var marker = new google.maps.Marker({
                position: results[0].geometry.location,
                icon: new google.maps.MarkerImage('/img/icons/map-thumbnail.png', null, null, null, new google.maps.Size(42, 60)),
                map: map
              });
            }
          });
        }
      }
    }
  };

  // Expose public methods & properties
  return {
    init: init,
    initMaps: initMaps,
    initGoogleMapBlocks: initGoogleMapBlocks
  };
}();