"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.MenuMobile = function MenuMobile() {
	"use strict";

	/**
  * jQuery elements
  * @private
  */

	/**
  * Has the class been initialized?
  * @private
  */

	var inited = false;

	/**
  * Initializes the class.
  * @public
  */
	var init = function init() {
		// Abort if already initialized
		if (inited) {
			return false;
		}

		inited = true;

		var $trigger = document.querySelector(".mobile-menu-trigger");
		var $triggerClose = document.querySelector(".navigation-close");

		if ($trigger && $triggerClose) {
			$trigger.addEventListener("click", function (e) {
				var $main = document.querySelector(".site-container");
				var $menu = document.querySelector(".navigation-mobile");

				$main.classList.toggle("active");
				$menu.classList.toggle("is-active");

				// Add overflow: hidden to html element to prevent background scrolling
				var $html = document.querySelector("html");

				$html.classList.toggle("mobile-is-open");
			});

			$triggerClose.addEventListener("click", function (e) {
				var $main = document.querySelector(".site-container");
				var $menu = document.querySelector(".navigation-mobile");

				$main.classList.toggle("active");
				$menu.classList.toggle("is-active");

				// Add overflow: hidden to html element to prevent background scrolling
				var $html = document.querySelector("html");

				$html.classList.toggle("mobile-is-open");
			});

			// Activate Follow Sticky
			activateMenuFollow();
		}

		// Open next aside when location menu is clicked
		var buttons = document.querySelectorAll(".link-subnav--location");

		if (buttons) {
			buttons.forEach(function (button) {
				button.addEventListener("click", activateSubMenu);

				// Add 'link-subnav--default' class to current province
				// Get locations from 'data-locations' attribute and parse them
				var locations = JSON.parse(button.getAttribute("data-locations"));

				// Get local storage defaultLatLng
				var defaultLatLng = JSON.parse(localStorage.getItem("defaultLatLng"));

				if (defaultLatLng) {
					// add if statement to avoid throwing error in console
					var defaultIsInProvince = locations.some(function (location) {
						return location.lat == defaultLatLng.lat && location.lng == defaultLatLng.lng;
					});

					if (defaultIsInProvince) {
						button.classList.add("link-subnav--default");
					} else {
						button.classList.remove("link-subnav--default");
					}
				}
			});
		}

		return true;
	};

	var activateSubMenu = function activateSubMenu(e) {
		var asideSubMenu = document.querySelector(".navigation-mobile--submenu");
		var back = asideSubMenu.querySelector("[data-back]");

		// Set active state to asideSubMenu
		asideSubMenu.classList.add("is-active");

		// Set province text
		var province = asideSubMenu.querySelector("[data-province]");
		province.innerHTML = e.currentTarget.innerHTML;

		// Get locations from 'data-locations' attribute and parse them
		var locations = JSON.parse(e.currentTarget.getAttribute("data-locations"));

		// Get local storage defaultLatLng
		var defaultLatLng = JSON.parse(localStorage.getItem("defaultLatLng"));

		// For loop each location and generate html
		var transformedLocations = locations.reduce(function (prev, curr) {
			return prev + (curr.isComingSoon === "false" ? "<a class=\"link-subnav link-subnav--location " + (defaultLatLng.lat == curr.lat && defaultLatLng.lng == curr.lng ? "link-subnav--default" : "") + "\" href=\"" + curr.url + "\" data-location\n\t\t\t\t\t>" + curr.title + "</a>" : "<p class=\"link-subnav link-subnav--location\" data-location\n\t\t\t\t>" + curr.title + " <span>(Coming soon)</span></p>");
		}, "");

		// Append to province element
		back.insertAdjacentHTML("afterend", transformedLocations);

		if (back) {
			back.addEventListener("click", function () {
				// Close sub menu
				asideSubMenu.classList.remove("is-active");

				// Reset province text
				province.innerHTML = "";

				// Delete location nodes
				var locationLinks = asideSubMenu.querySelectorAll("[data-location]");

				if (locationLinks) {
					locationLinks.forEach(function (location) {
						return location.remove();
					});
				}
			});
		}
	};

	var activateMenuFollow = function activateMenuFollow() {
		var $nav = document.querySelector("#main-header");
		var buffer = 10;

		var lastScrollTop = 0;

		var st = document.documentElement.scrollTop;

		if (st >= 158) {
			$nav.classList.remove("is-sticky");
		} else {
			$nav.classList.remove("is-sticky");
		}

		window.addEventListener("scroll", function () {
			var st = document.documentElement.scrollTop;

			// console.log(st);

			if (st >= 158) {
				$nav.classList.add("is-sticky");
			} else {
				$nav.classList.remove("is-sticky");
			}

			// if (st > lastScrollTop){
			//   // downscroll code
			//   $nav.classList.add( "hide-nav-bar" );
			// } else if( st < ( lastScrollTop - buffer ) ){
			//   // upscroll code
			//   $nav.classList.remove( "hide-nav-bar" );
			// }

			// if(st <= 15){
			//   $nav.classList.remove( "hide-nav-bar" );
			// }

			lastScrollTop = st;
		});
	};

	// Expose public methods & properties
	return {
		init: init
	};
}();